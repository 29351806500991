import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import CoursePage from './components/CoursePage';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Header from './components/Header'; //Import the header component
import Footer from './components/Footer';  //ImportFooter component
import './App.css';


function App() {
    return (
	    <Router>
		    <div className="App">
                <Routes>
			        <Route path="/HomePage" element={<HomePage />} />
			        <Route path="/CoursePage" element={<CoursePage />} />
			        <Route path="/AboutUs" element={<AboutUs />} />
			        <Route path="/ContactUs" element={<ContactUs />} />
                </Routes>
                <Footer />
		    </div>
    	</Router>
    );
}

export default App;
