import React, { useState } from 'react';
import Header from './Header';
//import { Link } from 'react-router-dom'; 
import './styles/ContactUs.css';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [feedback, setFeedback] = useState('');

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://185.75.20.71:3001/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setFeedback('Message sent successfully!');
                setFormData({ name: "", email: '', message: '' });
            } else {
                setFeedback('Failed to submit form');
            }
        }catch (error) {
            console.error('Error:', error);
            setFeedback('Error submitting form');
        }
    };
        
	return (
		<div className="contactus">
            <Header />
			<section className="contact-info">
				<h1>Contact Us</h1>
				<p>Address: 101 Apollo Drive, Rosedale, North Shre</p>
				<p>Phone: +64 28 8510 3185 +64 204 124 7123</p>
				<p>Email: accounts@aplusglobalacademy.com</p>
			</section>

			<section className="contact-form">
				<h2>Send Us a Message</h2>
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<label htmlFor="name">Name</label>
						<input 
                            type="text" 
                            id="name" 
                            value={formData.name}
                            onChange={handleChange}
                            required 
                        />
					</div>
					<div className="form-group">
						<label htmlFor="email">Email</label>
						<input 
                            type="email" 
                            id="email" 
                            value={formData.email}
                            onChange={handleChange}
                            required 
                        />
					</div>
					<div className="form-group">
						<label htmlFor="message">Message</label>
						<textarea 
                            id="message" 
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
					</div>
					<button type="submit">Send</button>
				</form>
                {feedback && <p>{feedback}</p>}
			</section>
		</div>
	);
};

export default ContactUs; //Exporting the component as default
