import React, { useState } from 'react';
import Header from './Header';
import CourseCategories from './CourseCategories';
import ExpandedCategory from './ExpandedCategory';
import './styles/CoursePage.css';

function CoursePage() {
	const [expanded, setExpanded] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState('');
	
	const handleExpand = (category) => {
		setExpanded(true);
		setSelectedCategory(category);
	};

	return (
		<div className="course-page">
			<Header />
			<CourseCategories onExpand={handleExpand} />
			{expanded && <ExpandedCategory category={selectedCategory} />}
		</div>
	);
}

export default CoursePage; 
