import React from 'react';
import './styles/ExpandedCategory.css';

const subcategories = {
    Math: ['Cambridge Math', 'NCEA Math', 'IB Math', 'HSPT', 'Competition Math'],
    English: ['Long Term Classes', 'HSPT', 'ESOL Classes'],
    Science: ['Primary', 'Intermediate', 'Junior High School', 'Y11-Y13'],
    Languages: ['French', 'German', 'Spanish', 'Chinese'],
    Coding: ['Roblox Project Development', 'Scratch Block Coding', 'Python', 'JavaScript'],
};
 
function ExpandedCategory({ category }) {
	return (
		<div className="expanded-category">
			<h3>{category} Courses</h3>
			<div className="courses">
                {subcategories[category].map(subcategory => (
                    <div key={subcategory} className="course">
                        {subcategory}
                    </div>
            ))}
			</div>
		</div>
	);
}

export default ExpandedCategory;
			
