import React, { useState } from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import './styles/AboutUs.css';

const staffMembers = [
    {
        name: 'Zeshan S.',
        role: 'General Manager',
        photo: 'path/to/photo1.jpg',
        subjects:['',''],
        intro: 'Zeshan has a bachelor\'s degree in software engineering and oversees all aspects of operations.',
    },
    {
        name: 'Anna G.',
        role: 'Director of School',
        photo: 'path/to/photo2.jpg',
        subjects: ['Chinese'],
        intro:'Anna is a proud mother of two outstanding boys, she comes from a family of teachers and teaches Chinese. A talented multitasker!',
    },
];

const AboutUs = () => {
    const [expandedMember, setExpandedMember] = useState(null);
    const handleNameClick = (index) => {
        setExpandedMember(expandedMember === index ? null : index);
    };            

	return (
	    <div className="aboutus">
			<Header />

			<section className="about-info">
				<h1>About Us</h1>
				<p>A+ Global Academy has been providing quality tutoring services since 2019. We offer personalized learning experiences in various subjects for students from year 1 to year 13 and beyond.</p>
			</section>

			<section className="team">
				<h2>Meet Our Team</h2>
				<div className="team-list">
                    {staffMembers.map((member, index) => (
                        <div key={index} className="team-member">
                            <img src={member.photo} alt={member.name} className="team-photo" />
                            <h3 onClick={() => handleNameClick(index)}>{member.name}</h3>
                            {expandedMember === index && <p className="intro">{member.intro}</p>}
                            <p>{member.role}</p>
                            <div className="subjects">
                                {member.subjects.map((subject, idx) => (
                                    <Link key={idx} to={`/CoursePage#${subject}`}>
                                        {subject}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
}

export default AboutUs;
