import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Footer.css'; 

const Footer = () => {
    return (
        <footer className="footer">
            <nav className="footer-nav">
                <Link to="/HomePage" className="footer-link">Home</Link>
                <Link to="/CoursePage" className="footer-link">Courses</Link>
                <Link to="/AboutUs" className="footer-link">About Us</Link>
                <Link to="/ContactUs" className="footer-link">Conact Us</Link>
            </nav>
        </footer>
    );
}

export default Footer;                
