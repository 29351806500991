import React from 'react';
import './styles/CourseCategories.css';

const categories = [
    { name: 'Math', displayName: 'Math' },
    { name: 'English', displayName: 'English' },
    { name: 'Science', displayName: 'Science' },
    { name: 'Languages', displayName: 'Languages' },
    { name: 'Coding', displayName: 'Coding' },
];

function CourseCategories({ onExpand }) {
	return (
		<div className="course-categories">
            {categories.map(category => (
                <div 
                    key={category.name}
                    className="category"
                    onClick={() => onExpand(category.name)}
                >
                    {category.displayName}
                </div>
            ))}
            <div
                className="category view-more"
                onClick={() => onExpand('Math')}
            >
                View More
            </div>
        </div>
    );
}
export default CourseCategories;
