import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles/Header.css';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        console.log("Menu state before toggle: ", menuOpen);
        setMenuOpen(!menuOpen);
        console.log("Menu state after toggle: ", menuOpen);
    }

	return (
		<header className="header">
            <div className="logo-container">
                <img src="/images/logoNoBK.png" alt="A+ Global Academy Logo" className="logo-img" />
			    <div className="logo-text">A+ Global Academy</div>
            </div>
            <div className="hamburger-menu" onClick={toggleMenu}>
                <div className="line" />
                <div className="line" />
                <div className="line" />
            </div>
            <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
                <div className="menu-items">
                    <Link to="/HomePage">Home</Link>
                    <Link to="/CoursePage">Courses</Link>
                    <Link to="/AboutUs">About Us</Link>
                    <Link to="/ContactUs">Contact Us</Link>
                </div>
            </nav>
		</header>
	);
}

export default Header;

