import React from 'react';
import Header from './Header';
//import { Link } from 'react-router-dom';
import './styles/HomePage.css';

const HomePage = () => {
	return (
		<div className="homepage">
            <Header />
   			<section className="welcome-banner">
    			<h1>Welcome to A+ Global Academy</h1>
	    		<p>Your gateway to academic excellence.</p>
		    </section>
		    <section className="overview">
			    <p>A+ Global Academy offers comprehensive tutoring services from year 1 to year 13 in various subjects.</p>
	    	</section>
		    <section className="services">
			    <h2>Our Services</h2>
			    <div className="service-list">
				    <div className="service-item">Math</div>
				    <div className="service-item">Science</div>
				    <div className="service-item">English</div>
			    	<div className="service-item">Coding</div>
				    <div className="service-item">Languages</div>
			    </div>
		    </section>
		    <section className="actions">
			    <button className="explore-button">Explore Courses</button>
			    <button className="contact-button">Contact Us</button>
		    </section>
	    </div>
	);
};

export default HomePage;
